enum FeatureFlags {
  DUAL_ENVIRONMENTS_FLAG = 'DUAL_ENVIRONMENTS_FLAG',
  ORG_ASYNC_REQUESTS_ENABLED = 'ORG_ASYNC_REQUESTS_ENABLED',
  ORG_USE_TRAFFIC_RAMP_UP_WHILE_PROMOTING = 'ORG_USE_TRAFFIC_RAMP_UP_WHILE_PROMOTING',
  ORG_CHAIN_UI_ENABLED = 'ORG_CHAIN_UI_ENABLED',
  ORG_ENABLE_STABLE_ENVIRONMENTS = 'ORG_ENABLE_STABLE_ENVIRONMENTS',
  ORG_ENABLE_CHAINS_STABLE_ENVIRONMENTS = 'ORG_ENABLE_CHAINS_STABLE_ENVIRONMENTS',
}

export { FeatureFlags };
